import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Input, Checkbox } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './Registration.css';

const Registration = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    surname: '',
    name: '',
    carName: '',
    model: '',
    autoNumber: '',
    phoneNumber: '',
    password: '',
    passwordAgain: '',
    noCar: false
  });

  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');

  useEffect(() => {
    const token = sessionStorage.getItem('token') || localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { id, value, checked, type } = e.target;
    setForm({
      ...form,
      [id]: type === 'checkbox' ? checked : value
    });
  };

  const handleCheckboxChange = (e) => {
    setForm({
      ...form,
      noCar: e.target.checked
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.surname) newErrors.surname = 'Фамилия обязательна';
    if (!form.name) newErrors.name = 'Имя обязательно';
    if (!form.phoneNumber) newErrors.phoneNumber = 'Номер телефона обязателен';
    
    if (!form.noCar) {
      if (!form.carName) newErrors.carName = 'Марка автомобиля обязательна';
      if (!form.model) newErrors.model = 'Модель автомобиля обязательна';
      if (!form.autoNumber) newErrors.autoNumber = 'Гос номер автомобиля обязателен';

      const autoNumberRegex = /^[АВЕКМНОРСТУХ]{1}\d{3}[АВЕКМНОРСТУХ]{2}\d*$/i;
      if (form.autoNumber && !autoNumberRegex.test(form.autoNumber)) newErrors.autoNumber = 'Гос номер автомобиля должен быть в формате А000АА регион';
    }

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!form.password) newErrors.password = 'Пароль обязателен';
    else if (!passwordRegex.test(form.password)) newErrors.password = 'Пароль должен быть не менее 8 символов, содержать хотя бы одну заглавную букву и одну цифру, и состоять только из латинских букв';
    
    if (form.password !== form.passwordAgain) newErrors.passwordAgain = 'Пароли не совпадают';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch('/api/register.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            surname: form.surname,
            name: form.name,
            carName: form.noCar ? '' : form.carName,
            model: form.noCar ? '' : form.model,
            autoNumber: form.noCar ? '' : form.autoNumber,
            phoneNumber: form.phoneNumber,
            password: form.password,
            noCar: form.noCar
          })
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            sessionStorage.setItem('token', data.token);
            console.log('Регистрация успешна', data);
            navigate('/');
          } else {
            setServerError(data.error || 'Неизвестная ошибка');
          }
        } else {
          const errorText = await response.text();
          setServerError(errorText || 'Ошибка при регистрации');
        }
      } catch (error) {
        setServerError('Ошибка сети: ' + error.message);
      }
    }
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    const validValue = value.replace(/[^0-9+]/g, '');
    setForm({
      ...form,
      phoneNumber: validValue
    });
  };

  const handleAutoNumberChange = (e) => {
    const { value } = e.target;
    const validChars = /[^0-9АВЕКМНОРСТУХ]/gi;
    const validValue = value.replace(validChars, '').toUpperCase();
    setForm({
      ...form,
      autoNumber: validValue
    });
  };

  return (
    <div className="container">
      <Helmet>
        <title>Регистрация</title>
      </Helmet>
      <h2 className="text-center">Регистрация</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            id="surname"
            className={`input-form ${errors.surname ? 'error' : ''}`}
            placeholder="Фамилия"
            value={form.surname}
            onChange={handleChange}
          />
          {errors.surname && <span className="error">{errors.surname}</span>}
        </div>

        <div className="form-group">
          <input
            type="text"
            id="name"
            className={`input-form ${errors.name ? 'error' : ''}`}
            placeholder="Имя"
            value={form.name}
            onChange={handleChange}
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <div className="form-group">
          <input
            type="text"
            id="carName"
            className={`input-form ${errors.carName ? 'error' : ''}`}
            placeholder="Марка автомобиля"
            value={form.carName}
            onChange={handleChange}
            disabled={form.noCar}
          />
          {errors.carName && <span className="error">{errors.carName}</span>}
        </div>

        <div className="form-group">
          <input
            type="text"
            id="model"
            className={`input-form ${errors.model ? 'error' : ''}`}
            placeholder="Модель автомобиля"
            value={form.model}
            onChange={handleChange}
            disabled={form.noCar}
          />
          {errors.model && <span className="error">{errors.model}</span>}
        </div>

        <div className="form-group">
          <input
            type="text"
            id="autoNumber"
            className={`input-form ${errors.autoNumber ? 'error' : ''}`}
            placeholder="Гос номер автомобиля"
            value={form.autoNumber}
            onChange={handleAutoNumberChange}
            disabled={form.noCar}
          />
          {errors.autoNumber && <span className="error">{errors.autoNumber}</span>}
          <div className="form-group form-check">
            <Checkbox
              id="noCar"
              checked={form.noCar}
              onChange={handleCheckboxChange}
            >
              У меня нет автомобиля
            </Checkbox>
          </div>
        </div>

        <div className="form-group">
          <input
            type="text"
            id="phoneNumber"
            className={`input-form ${errors.phoneNumber ? 'error' : ''}`}
            placeholder="Номер телефона"
            value={form.phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
          {serverError && <div className="error">{serverError}</div>}
        </div>

        <div className="form-group">
          <Input.Password 
            type="password"
            id="password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            className={`input-form ${errors.password ? 'error' : ''}`}
            placeholder="Пароль"
            value={form.password}
            onChange={handleChange}
          />
          {errors.password && <span className="error">{errors.password}</span>}
        </div>

        <div className="form-group">
          <Input.Password 
            type="password"
            id="passwordAgain"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            className={`input-form ${errors.passwordAgain ? 'error' : ''}`}
            placeholder="Повторите пароль"
            value={form.passwordAgain}
            onChange={handleChange}
          />
          {errors.passwordAgain && <span className="error">{errors.passwordAgain}</span>}
        </div>

        <button type="submit" className="btn btn-primary btn-block">Зарегистрироваться</button>

        <div className="text-center mt-3">
          <span className="text-muted">Уже есть аккаунт? </span>
          <a href="/login" className="login-link">Войти</a>
        </div>
      </form>
    </div>
  );
}

export default Registration;
