import React, { useState, useEffect } from 'react';
import { Button, Drawer, Avatar, Badge } from 'antd';
import { CarOutlined, UserOutlined, MessageOutlined, EnvironmentOutlined, SettingOutlined, BookOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext'; // Импортируйте контекст
import './UserMenu.css'; // Импортируйте CSS файл

const UserMenu = () => {
  const [open, setOpen] = useState(false);
  const currentUser = useUser(); // Используйте хук контекста
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  const showDrawer = () => {
    if (currentUser) { // Проверяем, есть ли текущий пользователь
      setOpen(true);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (path) => {
    navigate(path);
    onClose();
  };

  // Проверка, загружен ли currentUser
  if (!currentUser) {
    return null; // Не рендерим UserMenu, если текущий пользователь не загружен
  }

  return (
    <>
      <Button type="text" className='btn-user' onClick={showDrawer} style={{ padding: 0 }}>
        <Avatar size={40} src={currentUser.avatarUrl} icon={!currentUser.avatarUrl && <UserOutlined />} />
      </Button>
      <Drawer
        placement="left"
        closable={false}
        onClose={onClose}
        open={open}
        className="custom-drawer"
        bodyStyle={{ padding: 0 }}
      >
        <div className="user-menu">
          <div className="user-info" onClick={() => handleMenuClick(`/profile/${currentUser.id}`)}>
            <Avatar size={64} src={currentUser.avatarUrl} />
            <div className='user-data'>
              <div className="user-name">{currentUser.name}</div>
              <div className="user-car">
                {currentUser.car && currentUser.licensePlate
                  ? `${currentUser.car}, ${currentUser.licensePlate}`
                  : currentUser.car || currentUser.licensePlate}
              </div>
            </div>
          </div>
          <div className="user-stats">
            <div className="stat-item" onClick={() => handleMenuClick('/friends')}>
              <UsergroupAddOutlined />
              <span className="stat-text">Друзья</span>
            </div>
            <div className="stat-item" onClick={() => handleMenuClick('/messages')}>
              <MessageOutlined />
              <span className="stat-text">Сообщения</span>
              <Badge className="custom-badge" count={currentUser.messagesCount} />
            </div>
            <div className="stat-item" onClick={() => handleMenuClick('/followers')}>
              <CarOutlined />
              <span className="stat-text">Попутчики</span>
            </div>
            <div className="stat-item" onClick={() => handleMenuClick('/places')}>
              <EnvironmentOutlined />
              <span className="stat-text">Места</span>
              <span className="stat-count">{currentUser.placesCount}</span>
            </div>
            <div className="stat-item" onClick={() => handleMenuClick('/fines')} style={{ marginTop: '24px' }}>
              <BookOutlined />
              <span className="stat-text">Штрафы</span>
              <Badge className="custom-badge" count={currentUser.finesCount} />
            </div>
          </div>
          <div className="menu-settings" onClick={() => handleMenuClick('/settings')}>
            <SettingOutlined />
            <span className="menu-settings-text">Настройки</span>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default UserMenu;
